import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import {
  getInheritedResourceStatusValue,
  getProfileResourceNameField,
} from "../../../../services/Helpers";
import { useQuery } from "react-query";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";

const ResourcesTable = ({
  selectedCategory,
  resourceType,
  os,
  profile,
  selectedTenantName,
  setResourcesAreLoading,
  setResourcesWereUpdated,
  resourcesWereUpdated,
}) => {
  const [profileResourceList, setProfileResourceList] = useState([]);
  const [matchedResources, setMatchedResources] = useState([]);
  const [categoriesResources, setCategoriesResources] = useState([]);

  const resourceNameField = getProfileResourceNameField(resourceType);
  const [allAppsLoading, setAllAppsLoading] = useState(false);

  const getAllSelectedResources = async (resourceType, os) => {
    const newOS = os === "mac" ? "macos" : os;
    try {
      const response = await axios.get(
        `${NGROK}/api/computers/operation-system/${resourceType}?os=${newOS}&tenantName=${selectedTenantName}&categoryName=${selectedCategory?.name}`,
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchResources = async (os, resourceType) => {
    let result;

    switch (resourceType) {
      case "Folder":
        result = await getAllSelectedResources("get-all-folders", os);
        break;
      case "Group":
        result = await getAllSelectedResources("get-all-groups", os);
        break;
      case "Publisher":
        result = await getAllSelectedResources("get-all-publishers", os);
        break;
      case "URLcontrol":
        result = await getAllSelectedResources("get-all-url-control", os);
        break;
      default:
        console.log("default");
    }

    if (result && result.content) {
      result.content.sort((a, b) => a.id - b.id);
      return result.content;
    }

    return [];
  };

  const fetchUrlControlList = async (os, profileId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/${os}/profiles/${profileId}/url-control`,
      );
      return response.data?.content;
    } catch (error) {
      console.error(error);

      // return { content: [] };
    }
  };

  const getFolders = async (os, profileId) => {
    const response = await axios.get(
      `${NGROK}/api/${os}/profiles/${profileId}/folders`,
    );
    if (response.data) {
      const foldersSortedById = response.data.content.sort(
        (a, b) => a.folderId - b.folderId,
      );
      return foldersSortedById;
    }
  };

  const getAllApplications = async (selectedProfileId) => {
    try {
      setAllAppsLoading(true);
      const newOS = os === "mac" ? "macos" : os;

      const response = await axios.get(
        `${NGROK}/api/profiles/get-all-apps-and-os?profileId=${selectedProfileId}&categoryName=${selectedCategory?.name}&os=${newOS}&tenantName=${selectedTenantName}`,
      );

      const manualExclusions = Array.isArray(selectedCategory?.manualExclusions)
        ? selectedCategory.manualExclusions
        : [];
      const exclusionNames = manualExclusions.map(
        (exclusion) => exclusion.name,
      );

      const filteredResponse = response?.data?.filter((entry) => {
        const appPath = entry?.defaultProfileAppsResource?.appPath;
        const isExcluded = exclusionNames.includes(appPath);
        return appPath && !isExcluded;
      });

      setProfileResourceList(filteredResponse || []);
    } catch (error) {
      console.error(error);
    }
    setAllAppsLoading(false);
  };

  const getResourceTrustLevel = (resource) => {
    const trustLevel = {
      ALLOWED: "ALLOWED",
      DISABLED: "DENIED",
      DYNAMIC: "TIMED",
      BLOCKED: "DENIED",
    };
    const resourceTrustLevel = `(${trustLevel[resource.profileResource]})`;

    const configuredProfile = selectedCategory?.profiles.find(
      (categoryProfile) => categoryProfile.name === profile.name,
    );
    if (configuredProfile)
      return resource.profileResource !== "INHERITED"
        ? resourceTrustLevel
        : `${resource.profileResource} ${getInheritedResourceStatusValue(
            resource,
          )}`;
    else return resource.profileResource;
  };

  const getDefaultProfileAppsResources = (resources) => {
    return resources
      ?.map((resource) => resource?.defaultProfileAppsResource)
      .filter(Boolean);
  };
  const getResources = async () => {
    try {
      let filteredProfileResources = [];

      if (resourceType === "App") {
        const defaultProfileAppsResources =
          getDefaultProfileAppsResources(profileResourceList);
        filteredProfileResources = defaultProfileAppsResources;
      } else {
        filteredProfileResources = profileResourceList?.filter(
          (profileResource) =>
            categoriesResources?.some(
              (categoryResource) =>
                profileResource[resourceNameField] === categoryResource.path ||
                profileResource[resourceNameField] === categoryResource.name,
            ),
        );
      }
      const uniqueData = filteredProfileResources.filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) => t[resourceNameField] === item[resourceNameField],
          ),
      );
      setMatchedResources(uniqueData);
    } catch (error) {
      console.error(error);
    }
  };

  const { data: profileUrlControlList, refetch: refetchUrls } = useQuery({
    queryKey: ["profileUrlControl", profile.os, profile.id],
    queryFn: () => fetchUrlControlList(profile.os, profile.id),
    enabled: !!profile && resourceType === "URLcontrol",
  });

  const {
    data: profileFolders,
    isLoading: foldersAreLoading,
    refetch: refetchFolders,
  } = useQuery({
    queryKey: ["profileFolders", os, profile.id],
    queryFn: () => getFolders(os, profile.id),
    enabled: !!os && !!profile.id && resourceType === "Folder",
  });

  const { data: allResources, isLoading: loading } = useQuery({
    queryKey: ["allResources", os, resourceType],
    queryFn: () => fetchResources(os, resourceType),
    enabled: !!os && !!resourceType,
  });

  const {
    data: appData,
    isLoading: appsAreLoading,
    refetch: refetchApps,
  } = useQuery({
    queryKey: ["profileFolders", os, profile.id],
    queryFn: () => getAllApplications(profile.id),
    enabled: !!os && !!profile.id && resourceType === "App",
  });

  useEffect(() => {
    if (!selectedCategory) {
      setCategoriesResources([]);
      return;
    }
    const filteredResources = allResources?.filter((resource) =>
      resource.categories?.some(
        (category) =>
          category.categoryName.toLowerCase() ===
          selectedCategory?.name.toLowerCase(),
      ),
    );

    setCategoriesResources(filteredResources || []);
  }, [allResources, selectedCategory]);

  useEffect(() => {
    switch (resourceType) {
      case "App":
        refetchApps();
        break;
      case "Folder":
        setProfileResourceList(profileFolders);
        break;
      case "URLcontrol":
        setProfileResourceList(profileUrlControlList);
        break;
      default:
        console.log("default");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resourceType,
    selectedCategory?.name,
    profileFolders,
    profileUrlControlList,
  ]);

  useEffect(() => {
    if (profileResourceList && categoriesResources) getResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resourceType,
    selectedCategory,
    profileResourceList,
    categoriesResources,
    resourceNameField,
  ]);

  /*   useEffect(() => {
    if (appsAreLoading || foldersAreLoading || loading)
      setResourcesAreLoading(true);
    else setResourcesAreLoading(false);
  }, [appsAreLoading, foldersAreLoading, loading, setResourcesAreLoading]); */

  useEffect(() => {
    const refetchOnCategoryUpdate = () => {
      setResourcesWereUpdated(false);

      switch (resourceType) {
        case "App":
          refetchApps();
          break;
        case "Folder":
          refetchFolders();
          break;
        case "URLcontrol":
          refetchUrls();
          break;
        default:
          console.log("default");
      }
    };

    if (resourcesWereUpdated) {
      setTimeout(() => {
        refetchOnCategoryUpdate();
      }, 2500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourcesWereUpdated]);

  if (foldersAreLoading || allAppsLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <Stack
      spacing={2}
      direction={"column"}
      sx={{
        width: "49%",
        height: "fit-content",
      }}
    >
      {/* <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Total Count: {matchedResources?.length || "0"}
      </Typography> */}
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead sx={{ backgroundColor: "#233044" }}>
            <TableRow>
              <TableCell sx={{ color: "white", width: 60 }} align="center">
                <Typography fontSize={17} fontWeight={600} width={60}>
                  Sl No
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "white",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography fontSize={17} fontWeight={600}>
                  {resourceType}
                </Typography>
              </TableCell>
              <MyHeaderTableCell align={"center"} className={"userName"}>
                <Typography fontSize={17} fontWeight={600}>
                  Trust level
                </Typography>
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {matchedResources?.length && selectedCategory
              ? matchedResources.map((resource, i) => (
                  <TableRow key={i}>
                    <TableCell component="td" scope="row" align="center">
                      {i + 1}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: 540,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Typography
                        variant="body1"
                        maxWidth={300}
                        fontSize={15}
                        sx={{ wordWrap: "break-word", marginRight: 10 }}
                      >
                        {resource[resourceNameField]}
                      </Typography>
                    </TableCell>
                    <TableCell component="td" scope="row" align="center">
                      {getResourceTrustLevel(resource)}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ResourcesTable;
