import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import { awsIcons } from "../AWSIcons";

const EC2Instances = ({ ec2Datas, setEc2Datas, tenantName }) => {
  const [selectedResourceInstance, setSelectedResourceInstance] =
    useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const selectResourceAndDetails = (instanceId) => {
    setSelectedResourceInstance(instanceId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const updateResource = async (arn) => {
    if (tenantName) {
      const response = await axios.put(
        `${NGROK}/api/aws/updateEc2InstancePublish?tenantName=${tenantName}&instanceARN=${arn}`,
      );
      fetchAllEc2Instance();
    }
  };

  const filteredec2Datas = ec2Datas?.filter((ec2Data) =>
    ec2Data.instanceName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const fetchAllEc2Instance = async (isFirst = false) => {
    if (isFirst) setLoading(true);
    if (tenantName) {
      const response = await axios.get(
        `${NGROK}/api/aws/allEC2Instances?tenantName=${tenantName}`,
      );
      setLoading(false);
      setEc2Datas(response.data.filter((resp) => resp.instanceName != null));
    }
  };

  useEffect(() => {
    fetchAllEc2Instance(true);
    const interval = setInterval(() => fetchAllEc2Instance, 5000);
    return () => clearInterval(interval);
  }, [tenantName]);

  const filteredData = ec2Datas.filter(
    (ec2Data) => ec2Data.instanceId === selectedResourceInstance,
  );

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Resources
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : ec2Datas.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Instance"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>

                    {filteredec2Datas.map((ec2Data, index) => (
                      <TableRow
                        key={index}
                        selected={
                          ec2Data.instanceId === selectedResourceInstance
                        }
                        onClick={() => {
                          selectResourceAndDetails(ec2Data.instanceId);
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {awsIcons.instances}
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                }}
                              >
                                {" "}
                                {ec2Data.instanceName}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Switch
                              checked={ec2Data.isManageInstance}
                              onChange={() =>
                                updateResource(ec2Data.instanceArn)
                              }
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: 16,
                              }}
                            >
                              Publish
                            </Typography>{" "}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No Instance Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedResourceInstance && filteredData ? (
          <Box
            width="50%"
            sx={{
              border: "1px solid",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
              }}
            >
              Details of {filteredData[0].instanceName}
            </Typography>
            <List>
              {filteredData.length > 0 ? (
                filteredData.map((ec2, index) => (
                  <div key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Instance Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.instanceName}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Instance ARN</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.instanceArn}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Instance Type</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.instanceType}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Availablity Zone</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.availabilityZone}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Platform</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.platformDetails}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Private Ip Address</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.privateIpAddress}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>VPC Id</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.vpcId}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Subnet Id</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.subnetId}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Private DNS Name</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.privateDnsName}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Launched Time</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.launchTime}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography sx={{ fontSize: 18 }}>
                            <strong>Status</strong>
                          </Typography>
                        }
                        secondary={
                          <Typography sx={{ fontSize: 18 }}>
                            {ec2.instanceState}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </div>
                ))
              ) : (
                <Typography variant="body1"> No details available</Typography>
              )}
            </List>
          </Box>
        ) : null}
      </Stack>
    </>
  );
};

export default EC2Instances;
