import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { NGROK } from "../../../APIs";

const LoginToAWS = ({
  tenantName,
  timing,
  userName,
  generateTemporaryPassword,
  setGenerateTemporaryPassword,
}) => {
  const [credentials, setCredentials] = useState(null);

  useEffect(() => {
    const fetchCredentials = async () => {
      const response = await axios.get(
        `${NGROK}/api/aws/credentials?tenantName=${tenantName}&awsUsername=${userName}&accessTime=${timing}`,
      );
      if (response?.data) {
        const data = response?.data;
        setCredentials(data);
      }
      setGenerateTemporaryPassword(false);
    };

    fetchCredentials();
  }, [tenantName, timing, userName, setGenerateTemporaryPassword]);

  useEffect(() => {
    if (credentials) {
      const signInToken = credentials?.signinToken;
      if (signInToken) {
        const awsSignInUrl = `https://signin.aws.amazon.com/federation?Action=login&SigninToken=${signInToken}&Destination=${encodeURIComponent(
          "https://console.aws.amazon.com/",
        )}`;

        window.open(awsSignInUrl, "_blank");
      } else {
        console.error("SigninToken not found in the credentials response.");
      }
    }
  }, [credentials, timing]);

  return <Box></Box>;
};

export default LoginToAWS;
