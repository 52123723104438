import React, { useEffect, useState } from "react";
import { awsIcons, azureIcons } from "../AWS/AWSIcons";
import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import axios from "axios";
import { NGROK } from "../../../APIs";
import GCPVMInstances from "./Components/GCPVMInstances";
import GCPStorages from "./Components/GCPStorages";
import GCPDatabase from "./Components/GCPDatabase";

const gcpResources = [
  {
    id: "VM",
    name: "Virtual Machines",
    description:
      "Google Cloud Virtual Machines (VMs) are scalable, flexible compute resources that can be customized to run applications, manage workloads, and handle data processing.",
    icon: azureIcons.vmLarge,
  },
  {
    id: "STRGS",
    name: "Storages",
    description:
      "Google Cloud Storage offers scalable and secure object storage for storing and accessing your data, whether it's a small amount or a massive scale.",
    icon: azureIcons.strgs,
  },
  {
    id: "DB",
    name: "Databases",
    description:
      "Google Cloud Databases provide managed database services that are secure, reliable, and optimized for high performance and scalability.",
    icon: awsIcons.rdsLarge,
  },
];

const GcpResources = () => {
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const userData = useUserStore((state) => state.user);
  const [tenantList, setTenantList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchInstanceFortheResource = async (resource) => {
    setSelectedResource(resource);
  };

  const demandSync = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const filteredGcpResources = gcpResources.filter((resource) =>
    resource.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);
  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  useEffect(() => {
    if (gcpResources.length > 0) {
      setSelectedResource(gcpResources[0]);
      fetchInstanceFortheResource(gcpResources[0]);
    }
  }, []);

  return (
    <>
      <Box p={2}>
        <Stack direction="column" spacing={5} mb={3}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontSize: "22px",
            }}
          >
            GCP Resources
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              maxWidth: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box>
              {tenantList?.length > 0 && (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName || ""}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
              disabled={isSyncing}
              onClick={() => {
                demandSync();
              }}
            >
              {isSyncing ? "Syncing" : "Sync"}
            </Button>
          </Box>
        </Stack>
        <Stack direction="row" spacing={4}>
          <Box width="30%">
            <TableContainer component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "18px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Resource Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Resources"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>

                  {filteredGcpResources.map((resource) => (
                    <TableRow
                      key={resource.name}
                      selected={resource === selectedResource}
                      onClick={() => fetchInstanceFortheResource(resource)}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "8px",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                      }}
                    >
                      <TableCell>
                        <Stack
                          direction={"row"}
                          spacing={5}
                          display="flex"
                          alignItems="center"
                        >
                          <div>{resource.icon}</div>

                          <Box ml={2}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: 18,
                              }}
                            >
                              {resource.name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                maxWidth: "350px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {resource.description}
                            </Typography>
                          </Box>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box width="70%">
            {selectedResource?.id === "VM" && (
              <GCPVMInstances tenantName={selectedTenantName} />
            )}
            {selectedResource?.id === "STRGS" && (
              <GCPStorages tenantName={selectedTenantName} />
            )}
            {selectedResource?.id === "DB" && (
              <GCPDatabase tenantName={selectedTenantName} />
            )}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default GcpResources;
