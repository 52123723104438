import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import RequestsAzureTableBody from "./RequestsAzureTableBody";

const RequestAzureTable = ({
  adminNotifications,
  fromdetails,
  computerName,
}) => {
  const sortedNotification = adminNotifications.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
  );
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          border: 1,
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
        }}
      >
        <Table
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Created At
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Requested By
              </TableCell>

              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Resource Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Role Name
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Justification
              </TableCell>
              <TableCell
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
                align="center"
              >
                Privilege Level
              </TableCell>
              <TableCell
                align="center"
                colSpan={2}
                sx={{
                  color: "white",
                  fontSize: "16px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <RequestsAzureTableBody
            adminNotifications={sortedNotification}
            fromdetails={fromdetails}
            computerName={computerName}
          />
        </Table>
      </TableContainer>
    </>
  );
};

export default RequestAzureTable;
