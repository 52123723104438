import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../../components/StorageHelper";
import moment from "moment";
import SyncIcon from "@mui/icons-material/Sync";
import { awsIcons } from "../../AWS/AWSIcons";
import AzureUserGroups from "./AzureUserGroups";
import AccessAzureResources from "./AccessAzureResources";
import { NGROK } from "../../../../APIs";
import axios from "axios";

const AzureUserInfo = () => {
  const location = useLocation();
  const { user: initialUser } = location.state || { user: {} };
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedAccess, setSelectedAccess] = useState("resources");
  const [user, setUser] = useState(initialUser);
  const [groups, setGroups] = useState(initialUser?.groups || []);
  const [roleDefinitions, setRoleDefinitions] = useState(
    initialUser?.roleDefinitions || [],
  );

  const [isSyncing, setIsSyncing] = useState(false);
  const [syncInitial, setSyncInitial] = useState(false);
  const { displayName, azureId, syncedAt, preferredLanguage, azureUserId } =
    user;

  const getDisplayItems = (items) => items.slice(0, 2);
  const getMoreCount = (items) => items.length - 2;

  const handleSync = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        tenantName: latestTenant,
      },
    });
    setIsSyncing(false);
  };

  const getAllDetails = (val) => {
    setSelectedAccess(val);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSync}
          disabled={isSyncing}
        >
          {isSyncing ? "Syncing" : "Sync"}
        </Button>
      </Box>
      {syncInitial ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "65vh",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                fontSize: "2rem",
                animation: "blinking 1.5s infinite",
              }}
            >
              <SyncIcon size="2rem" />
            </Box>
          </Box>
          <Typography sx={{ mt: 2, fontSize: "1.2rem", color: "gray" }}>
            Please wait. We are syncing initial user details
          </Typography>

          <style>
            {`
          @keyframes blinking {
            0% {
              opacity: 0.5;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.5;
            }
          }
        `}
          </style>
        </Box>
      ) : (
        <Stack direction={"column"} spacing={5}>
          <Paper elevation={4} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2, fontSize: "18px" }}
            >
              {displayName}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Azure ID:
                </Typography>
                <Typography variant="body1">
                  {azureId || azureUserId || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Perferred language:
                </Typography>
                <Typography variant="body1">{preferredLanguage}</Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Created Date:
                </Typography>
                <Typography variant="body1">
                  {moment(syncedAt).format("DD/MM/YYYY HH:mm:ss")}
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Groups:
                </Typography>

                {groups?.length > 0 ? (
                  <>
                    {getDisplayItems(groups).map((group, index) => (
                      <Typography key={index} variant="body1">
                        {group}
                      </Typography>
                    ))}

                    {getMoreCount(groups) > 0 && (
                      <Tooltip
                        title={
                          <List>
                            {groups.map((group, index) => (
                              <ListItem key={index}>{group}</ListItem>
                            ))}
                          </List>
                        }
                        placement="top"
                      >
                        <Typography variant="body1" color="primary">
                          +{getMoreCount(groups)} more
                        </Typography>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Typography variant="body1">-</Typography>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "600", fontSize: "18px" }}
                >
                  Roles:
                </Typography>
                {roleDefinitions?.length > 0 ? (
                  <>
                    {getDisplayItems(roleDefinitions).map((roles, index) => (
                      <Typography key={index} variant="body1">
                        {roles}
                      </Typography>
                    ))}

                    {getMoreCount(roleDefinitions) > 0 && (
                      <Tooltip
                        title={
                          <List>
                            {roleDefinitions.map((role, index) => (
                              <ListItem key={index}>{role}</ListItem>
                            ))}
                          </List>
                        }
                        placement="top"
                      >
                        <Typography variant="body1" color="primary">
                          +{getMoreCount(roleDefinitions)} more
                        </Typography>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Typography variant="body1">-</Typography>
                )}
              </Grid>
            </Grid>
          </Paper>

          <Stack direction={"row"} spacing={2}>
            <Box width={"30%"}>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    height: "fit-content",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                    },
                    "& td, & th": {
                      border: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Privileges</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <>
                      <TableRow
                        key={"resources"}
                        selected={selectedAccess === "resources"}
                        onClick={() => {
                          getAllDetails("resources");
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {awsIcons.resources}
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                }}
                              >
                                Resources
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        selected={selectedAccess === "groups"}
                        key={"groups"}
                        onClick={() => {
                          getAllDetails("groups");
                        }}
                        sx={{
                          "&:hover": {
                            background: "#f5f5f5",
                            cursor: "pointer",
                          },
                          "&.Mui-selected": {
                            background: "#233044 !important",
                            "& td, & th": {
                              color: "#fff",
                            },
                          },
                          "& td, & th": {
                            border: "1px solid #233044",
                            fontSize: "18px",
                          },
                        }}
                      >
                        <TableCell>
                          <Box display="flex" alignItems="center">
                            {awsIcons.groups}
                            <Box ml={2}>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: "18px",
                                }}
                              >
                                Groups
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            {selectedAccess === "resources" ? (
              <AccessAzureResources user={user} groups={groups} />
            ) : null}
            {selectedAccess === "groups" ? (
              <AzureUserGroups user={user} groups={groups} />
            ) : null}
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default AzureUserInfo;
