import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { azureIcons } from "../../AWS/AWSIcons";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import moment from "moment";

const AccessGCPVMInstance = ({ tenantName }) => {
  const [vms, setVms] = useState([]);
  const [projectId, setProjectId] = useState(null);

  const fetchVmDatas = async (isFirst = false, tenantName) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/gcp/virtual-machines?tenantName=${tenantName}&projectId=${projectId}`,
      );
      if (response?.data) {
        setVms(response?.data);
      }
    } catch (e) {
      setVms([]);
    }
  };

  useEffect(() => {
    if (tenantName != null && projectId != null) fetchVmDatas(true, tenantName);
  }, [tenantName, projectId]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: tenantName,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
    } else {
      setProjectId(null);
    }
  };

  useEffect(() => {
    fetchGCPProjectId();
  }, [tenantName]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Zone</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vms?.length > 0 ? (
              vms.map((vm, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {azureIcons.vm}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {vm.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {vm.zone}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {moment(vm.syncedAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No VMS available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessGCPVMInstance;
