import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import TrustLevelSelect from "../components/TrustLevelSelect";

const CategoriesTable = ({
  resourcesAreLoading,
  selectedTenantName,
  selectedCategory,
  setSelectedCategory,
  setResourcesWereUpdated,
  resourceType,
  profile,
  os,
}) => {
  const userEmail = useUserStore((state) => state.user.email);
  const [profileDetails, setProfileDetails] = useState(profile);
  const [categories, setCategories] = useState([]);

  function getProfilesToSend(profiles, profile) {
    const index = profiles.findIndex((p) => p.name === profile.name);
    if (index !== -1) {
      if (!JSON.stringify(profiles[index]) !== JSON.stringify(profile)) {
        profiles[index] = profile;
      }
    } else {
      profiles.push(profile);
    }
    return profiles;
  }

  const updateCategory = async (category, profile, trustLevel) => {
    const { reputationScore, name, searchPatterns, profiles } = category;
    const newOs = os === "mac" ? "macos" : os;
    const resourceTypeModified =
      resourceType === "URLcontrol" ? "url" : resourceType;

    const profileToConfigure = {
      profileId: profileDetails.id || profileDetails.profileId,
      name: profileDetails.name,
      trustLevel,
    };

    const updatedProfiles = getProfilesToSend(profiles, profileToConfigure);

    try {
      const response = await axios.put(`${NGROK}/api/category`, {
        reputationScore: reputationScore,
        oldName: name,
        newName: name,
        oldSearchPatterns: searchPatterns,
        os: newOs.toUpperCase(),
        resourceType: resourceTypeModified.toUpperCase(),
        profiles: updatedProfiles,
        userEmail,
        tenantName: selectedTenantName,
      });

      const updatedCategories = categories.map((category) =>
        category.name === response.data.name ? response.data : category,
      );

      setCategories(updatedCategories);

      setResourcesWereUpdated(true);
    } catch (error) {
      setResourcesWereUpdated(false);
      console.log(error, "error during updating category");
    }
  };

  const getProfilesRenderingConditions = (category) => {
    return (
      category.profiles?.length > 0 &&
      category.profiles.some(
        (categoryProfile) =>
          profile.name === categoryProfile.name &&
          profile.id === categoryProfile.profileId,
      )
    );
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const getCategories = async (resourceType, os) => {
      try {
        const newOs = os === "mac" ? "macos" : os;
        const response = await axios.get(
          `${NGROK}/api/category/${resourceType}?tenantName=${selectedTenantName}&os=${newOs?.toUpperCase()}`,
        );
        return response.data;
      } catch {
        console.error("error during fetching categories");
      }
    };

    const fetchCategories = async () => {
      switch (resourceType) {
        case "App":
          const appsCategories = await getCategories("for-apps", os);
          setCategories(appsCategories);
          break;
        case "Folder":
          const foldersCategories = await getCategories("for-folders", os);
          setCategories(foldersCategories);
          break;
        case "Group":
          const groupsCategories = await getCategories("for-groups", os);
          setCategories(groupsCategories);
          break;
        case "Publisher":
          const publishersCategories = await getCategories(
            "for-publishers",
            os,
          );
          setCategories(publishersCategories);
          break;
        case "URLcontrol":
          const urlControlCategories = await getCategories(
            "for-url-control",
            os,
          );
          setCategories(urlControlCategories);
          break;
        default:
          console.log("default");
      }
    };
    fetchCategories();
  }, [resourceType, selectedTenantName, os]);

  if (resourcesAreLoading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "49%", height: "fit-content" }}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                width: 75,
                color: "white",
                backgroundColor: "#233044",
              }}
            >
              <Typography fontSize={17} fontWeight={600}>
                Sl No
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                maxWidth: 300,
                color: "white",
                backgroundColor: "#233044",
              }}
            >
              <Typography fontSize={17} fontWeight={600}>
                Categories
              </Typography>
            </TableCell>
            <TableCell
              sx={{ color: "white", backgroundColor: "#233044" }}
              align="center"
            >
              <Typography fontSize={17} fontWeight={600}>
                Trust level
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.length
            ? categories.reduce((acc, category) => {
                if (
                  category.operationSystem.toUpperCase() ===
                    profile.os.toUpperCase() ||
                  (category.operationSystem.toUpperCase() === "MACOS" &&
                    profile.os.toUpperCase() === "MAC")
                ) {
                  acc.push(
                    <TableRow
                      key={uuidv4()}
                      sx={{
                        border: "1px solid #233044",
                        "& td, & th": { border: 1 },
                      }}
                      onClick={() => {
                        handleCategorySelect(category);
                      }}
                    >
                      <TableCell component="td" scope="row">
                        {acc.length + 1}{" "}
                      </TableCell>
                      <TableCell
                        className={
                          `${category.name}${category.operationSystem}` ===
                          `${selectedCategory?.name}${selectedCategory?.operationSystem}`
                            ? "userRow activeUserRow"
                            : "userRow"
                        }
                      >
                        {category.name}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {(getProfilesRenderingConditions(category) &&
                          category.profiles.map(
                            (categoryProfile) =>
                              profile.id === categoryProfile.profileId && (
                                <TrustLevelSelect
                                  key={`${categoryProfile.profileId || "empty"}${
                                    categoryProfile.name || "empty"
                                  }`}
                                  profile={categoryProfile}
                                  category={category}
                                  updateCategory={updateCategory}
                                />
                              ),
                          )) || (
                          <TrustLevelSelect
                            profile={profile}
                            category={category}
                            updateCategory={updateCategory}
                          />
                        )}
                      </TableCell>
                    </TableRow>,
                  );
                }
                return acc;
              }, [])
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoriesTable;
