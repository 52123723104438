import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";

import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
} from "../../../services/Helpers";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const ProfilePrivilegeManagement = ({
  userData,
  tenantName,
  showCount,
  setShowCount,
  setGroupsSearchValues,
  groupsSearchValues,
  selectedProfile,
}) => {
  const tableRef = useRef();
  const [groups, setGroups] = useState([]);

  const [filterGroupsValue, setFilterGroupsValue] = useState("ALL");

  const debouncedSearchTerm = useDebouncedValue(groupsSearchValues, 1000);

  const findGroup = (value) => {
    setGroupsSearchValues(value);
  };

  const changeGroupStatus = async (group, groupStatus) => {
    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
          ? "BLOCKED"
          : groupStatus === "DYNAMIC"
            ? "DYNAMIC"
            : "INHERITED";

    setGroups(
      groups.map((el) =>
        el.groupId === group.groupId ? { ...group, profileResource } : el,
      ),
    );
    try {
      await axios.put(`${NGROK}/api/profiles/groups/update`, {
        email: userData.email,
        groupId: group.groupId,
        groupStatus,
        profileId: group.profileId,
        tenantName,
      });
    } catch (error) {
      setGroups(groups.map((el) => (el.id === group.id ? group : el)));
    }
  };

  useEffect(() => {
    const getProfileGroups = async (profile) => {
      setGroups(undefined);

      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/membership`,
      );

      if (response.data) {
        const groupsSortedById = response.data.content.sort(
          (a, b) => a.groupId - b.groupId,
        );
        setGroups(groupsSortedById);
      }
    };

    getProfileGroups(selectedProfile);
  }, [selectedProfile]);

  const handleFilterResourceValue = (e) => {
    setFilterGroupsValue(e.target.value);
  };

  const uniqueGroupVal = (Array.isArray(groups) ? groups : []).reduce(
    (acc, group) => {
      if (!acc.some((item) => item.groupId === group.groupId)) {
        acc.push(group);
      }
      return acc;
    },
    [],
  );

  const filteredGroups = getFilteredProfileResources(
    debouncedSearchTerm,
    filterGroupsValue,
    uniqueGroupVal,
  );

  const uniqueGroups = filteredGroups.reduce((acc, group) => {
    if (!acc.some((item) => item.groupId === group.groupId)) {
      acc.push(group);
    }
    return acc;
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const table = tableRef.current;
        const rows = table.querySelectorAll("tr");

        if (rows.length >= showCount) {
          const fiftiethRow = rows[showCount - 1];
          const rect = fiftiethRow.getBoundingClientRect();
          const tableRect = table.getBoundingClientRect();
          if (
            rect.top >= tableRect.top &&
            rect.bottom <= tableRect.bottom &&
            filteredGroups?.length > showCount
          ) {
            setShowCount((prevCount) => prevCount + 50);
          }
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [tableRef, groups, showCount, filteredGroups, setShowCount]);

  return (
    <>
      {groups && groups.length > 0 ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            <RadioAuthFilter
              filterResourceValue={filterGroupsValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForGroup"
              value={groupsSearchValues}
              onChange={(e) => findGroup(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Total Count: {uniqueGroups?.length || "0"}
          </Typography>

          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{
              height: "fit-content !important",
              maxHeight: "100vh !important",
              overflowY: "auto !important",
            }}
          >
            <Table
              sx={{
                minWidth: "fit-content",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Group
                  </MyHeaderTableCell>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Trust level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueGroups?.slice(0, showCount).map((group) => (
                  <TableRow
                    className="groupRow"
                    key={`${group.id}${group.groupId}${group.cn}`}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      className="groupRowName userName"
                      title={group.cn}
                    >
                      {group.cn}
                    </TableCell>
                    <TableCell>
                      <Box className="profilePrivilegeLevelTableCell">
                        {" "}
                        <Select
                          sx={{ minWidth: "100px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={getResourceSelectValue(group)}
                          size="small"
                          onChange={(event) =>
                            changeGroupStatus(group, event.target.value)
                          }
                        >
                          <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                          <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                          <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                          {group.trustLevel ? (
                            <MenuItem value={"INHERITED"}>
                              INHERITED {getInheritedResourceStatusValue(group)}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {filteredGroups?.length > showCount ? (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 1 },
                    }}
                  >
                    <TableCell colSpan={2}>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : groups === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any groups.
          </p>
        </Box>
      )}
    </>
  );
};

export default ProfilePrivilegeManagement;
