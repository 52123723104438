import {
  Alert,
  Box,
  Button,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import useUserStore from "../../../../services/userStore";
import { useDropzone } from "react-dropzone";

const GCPSetup = ({ tenantName }) => {
  const userData = useUserStore((state) => state.user);
  const [clientId, setClientId] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [privateKeyId, setPrivateKeyId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({});
  const [config, setConfig] = useState(null);
  const [configerror, setConfigerror] = useState(null);

  const handleUnconfigureSubmit = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/gcp/auth/delete-configuration?tenantName=${tenantName}&projectId=${projectId}`,
      );
      if (response?.data) {
        fetchGCPConfig();
      } else {
      }
    } catch (e) {}
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".json",
    onDrop: (files) => handleFileUpload(files),
  });

  const handleFileUpload = (files) => {
    if (files.length === 1) {
      const file = files[0];

      const formData = new FormData();

      formData.append("file", file);

      axios
        .post(
          `${NGROK}/api/gcp/auth/upload-configuration?tenantName=${tenantName}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        )
        .then((response) => {
          console.log("File uploaded successfully:", response.data);
          setClientId("");
          setClientEmail("");
          setPrivateKey("");
          setPrivateKeyId("");
          setProjectId("");
          setType("");
          fetchGCPConfig();
        })
        .catch((error) => {
          console.error(
            "Error uploading file:",
            error.response?.data || error.message,
          );
        });
    }
  };
  /*
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target.result);
          setClientId(json.client_id || "");
          setClientEmail(json.client_email || "");
          setPrivateKey(json.private_key || "");
          setPrivateKeyId(json.private_key_id || "");
          setProjectId(json.project_id || "");
          setType(json.type || "");
        } catch (error) {
          setConfigerror("Invalid JSON file");
        }
      };
      reader.readAsText(file);
         */
  const validateFields = () => {
    const newErrors = {};
    if (!clientId) newErrors.clientId = "Client ID is required";
    if (!clientEmail) newErrors.clientEmail = "Client Email is required";
    if (!privateKey) newErrors.privateKey = "Private Key is required";
    if (!privateKeyId) newErrors.privateKeyId = "Private Key ID is required";
    if (!projectId) newErrors.projectId = "Project ID is required";
    if (!type) newErrors.type = "Credential Type is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFields()) {
      try {
        let privateString = privateKey.replace(/\\n/g, "\n");
        const form = new FormData();
        form.append("client_id", clientId);
        form.append("client_email", clientEmail);
        form.append("private_key", privateString);
        form.append("private_key_id", privateKeyId);
        form.append("project_id", projectId);
        form.append("tenantName", tenantName);
        form.append("type", type);
        const formDataObject = {};
        form.forEach((value, key) => {
          formDataObject[key] = value;
        });
        const jsonString = JSON.stringify(formDataObject, null, 2);
        const jsonBlob = new Blob([jsonString], { type: "application/json" });
        const formDataWithJsonFile = new FormData();
        formDataWithJsonFile.append("file", jsonBlob, "form-data.json");
        axios
          .post(
            `${NGROK}/api/gcp/auth/upload-configuration?tenantName=${tenantName}`,
            formDataWithJsonFile,
            { headers: { "Content-Type": "multipart/form-data" } },
          )
          .then((response) => {
            console.log("File uploaded successfully:", response.data);
            fetchGCPConfig();
          })
          .catch((error) => {
            console.error(
              "Error uploading file:",
              error.response?.data || error.message,
            );
          });
      } catch (error) {
        console.error(error);
        setConfigerror("Error configuring GCP settings");
      }
    }
  };

  const fetchGCPConfig = async () => {
    try {
      const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
        params: {
          tenantName: tenantName,
        },
      });
      if (response.data && response?.data?.length !== 0) {
        setConfigerror(null);
        setConfig(response?.data);
        setClientId(response.data[0].clientId);
        setClientEmail(response.data[0].clientEmail);
        setPrivateKey(response.data[0].privateKey);
        setPrivateKeyId(response.data[0].privateKeyId);
        setProjectId(response.data[0].projectId);
        setType(response.data[0].type);
      } else {
        setConfigerror(null);
        setConfig(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchGCPConfig();
  }, [userData.email]);

  return (
    <Container maxWidth="md">
      {configerror ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setConfigerror(null)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setConfigerror(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {configerror}
          </Alert>
        </Snackbar>
      ) : null}
      {config ? (
        <Alert sx={{ marginTop: 1 }} severity="success">
          {"GCP account configured successfully"}
        </Alert>
      ) : null}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          GCP Initial Setup
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Client Id"
              variant="outlined"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              error={!!errors.clientId}
              helperText={errors.clientId}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Client Email"
              variant="outlined"
              value={clientEmail}
              onChange={(e) => setClientEmail(e.target.value)}
              error={!!errors.clientEmail}
              helperText={errors.clientEmail}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Private Key"
              variant="outlined"
              type="password"
              value={privateKey}
              onChange={(e) => setPrivateKey(e.target.value)}
              error={!!errors.privateKey}
              helperText={errors.privateKey}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Private Key ID"
              variant="outlined"
              value={privateKeyId}
              onChange={(e) => setPrivateKeyId(e.target.value)}
              error={!!errors.privateKeyId}
              helperText={errors.privateKeyId}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Project ID"
              variant="outlined"
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
              error={!!errors.projectId}
              helperText={errors.projectId}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label="Credentials Type"
              variant="outlined"
              value={type}
              onChange={(e) => setType(e.target.value)}
              error={!!errors.type}
              helperText={errors.type}
            />
          </Box>
          <Stack direction={"row"} spacing={5}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              disabled={config ? true : false}
            >
              Submit Configuration
            </Button>
            {config ? (
              <>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ mt: 2 }}
                  onClick={handleUnconfigureSubmit}
                >
                  Unconfigure GCP Account
                </Button>
              </>
            ) : null}
          </Stack>
        </form>
        {!config ? (
          <>
            <Box sx={{ my: 4, textAlign: "center" }}>
              <Typography variant="h6" color="textSecondary">
                OR
              </Typography>
            </Box>
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                padding: "20px",
                textAlign: "center",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1">
                Drag & Drop your GCP JSON file here or click to select
              </Typography>
            </Box>
          </>
        ) : null}
      </Box>
    </Container>
  );
};

export default GCPSetup;
