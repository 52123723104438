import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { azureIcons } from "../../AWS/AWSIcons";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import moment from "moment";
import { myLocalStorage } from "../../../../components/StorageHelper";

const AccessGCPDatabases = ({ tenantName }) => {
  const [projectId, setProjectId] = useState(null);
  const [dbs, setDbs] = useState([]);
  tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;
  const fetchDbDatas = async (isFirst = false, tenantName) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/gcp/databases?tenantName=${tenantName}&projectId=${projectId}`,
      );
      if (response?.data) {
        setDbs(response?.data);
      }
    } catch (e) {
      setDbs([]);
    }
  };

  useEffect(() => {
    if (tenantName != null && projectId != null) fetchDbDatas(true, tenantName);
  }, [tenantName, projectId]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: tenantName,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
    } else {
      setProjectId(null);
    }
  };

  useEffect(() => {
    fetchGCPProjectId();
  }, [tenantName]);
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Region </TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dbs?.length > 0 ? (
              dbs.map((db, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {azureIcons.database}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {db.name}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {db.region}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {moment(new Date()).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No Databses available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AccessGCPDatabases;
