import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Autocomplete,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  role: Yup.object().required("Role is required"),
  selectedMachine: Yup.array()
    .of(Yup.object())
    .required("Machines are required"),
});

const InviteModal = ({
  open,
  closeModal,
  tenantName,
  openSuccessInvite,
  openFailedInvite,
  rolesList,
  machineList,
}) => {
  const userData = useUserStore((state) => state.user);
  const [roleTypeFilter, setRoleTypeFilter] = useState("user");

  const filteredRoles = rolesList.filter((role) => {
    if (roleTypeFilter === "user") {
      if (userData.userRole === "epam-admin") {
        return (
          role.roleType === "users" &&
          role.roleName === "epam-user" &&
          role.roleName !== "super-user"
        );
      } else if (userData.userRole === "server-pam-admin") {
        return (
          role.roleType === "users" &&
          role.roleName !== "epam-user" &&
          role.roleName !== "super-user"
        );
      } else return role.roleType === "users" && role.roleName !== "super-user";
    }
    return role.roleType === "admin";
  });

  const callToInvite = async (values, { setSubmitting }) => {
    try {
      const email = values.email.toLowerCase().trim();
      const computerIdList = values.selectedMachine.map(
        (computer) => computer.id,
      );
      const userRole = values.role.roleId;
      const response = await axios.post(`${NGROK}/api/invite/send`, {
        ...values,
        email,
        tenantName,
        computerIdList,
        userRole,
      });
      let { isOk } = response?.data;
      if (isOk) {
        openSuccessInvite("Invite Sent Successfully");
        closeModal();
      } else {
        openFailedInvite("Invite already sent to this mail");
        closeModal();
      }
    } catch (error) {
      console.error("There was an error inviting the user", error);
      openFailedInvite("Failed to send invite. Try later.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      onClose={closeModal}
      aria-labelledby="user-invite-dialog-title"
    >
      <DialogTitle id="user-invite-dialog-title">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InsertInvitationIcon sx={{ mr: 1 }} />
            User Invite
          </Box>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            role:
              userData.userRole === "epam-admin"
                ? rolesList.find((data) => data.roleName === "epam-user") ||
                  null
                : userData.userRole === "server-pam-admin"
                  ? rolesList.find(
                      (data) => data.roleName === "server-pam-user",
                    ) || null
                  : null,
            selectedMachine: [],
          }}
          validationSchema={validationSchema}
          onSubmit={callToInvite}
        >
          {({ errors, touched, isSubmitting, setFieldValue, values }) => (
            <Form id="invite-form">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                }}
              >
                <Field
                  as={TextField}
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  error={touched.firstName && !!errors.firstName}
                  helperText={touched.firstName && errors.firstName}
                  fullWidth
                  margin="normal"
                  sx={{ mr: 1 }}
                />
                <Field
                  as={TextField}
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  error={touched.lastName && !!errors.lastName}
                  helperText={touched.lastName && errors.lastName}
                  fullWidth
                  margin="normal"
                  sx={{ ml: 1 }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  error={touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  fullWidth
                  margin="normal"
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                {userData.userRole !== "epam-admin" &&
                userData.userRole !== "server-pam-admin" ? (
                  <FormControl component="fieldset" sx={{ width: "100%" }}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <RadioGroup
                        row
                        value={roleTypeFilter}
                        onChange={(e) => {
                          setFieldValue("role", null);
                          setRoleTypeFilter(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="user"
                          control={<Radio />}
                          label="User"
                        />
                        <FormControlLabel
                          value="admin"
                          control={<Radio />}
                          label="Admin"
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                ) : null}

                <Autocomplete
                  options={filteredRoles}
                  getOptionLabel={(option) => option.roleName}
                  value={values.role}
                  disabled={userData.userRole === "epam-admin"}
                  onChange={(event, value) => {
                    setFieldValue("role", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Role"
                      variant="outlined"
                      error={touched.role && !!errors.role}
                      helperText={touched.role && errors.role}
                      fullWidth
                    />
                  )}
                />
              </Box>
              {values.role &&
                roleTypeFilter === "user" &&
                values.role.roleName !== "thirdparty-user" && (
                  <Box sx={{ mb: 2 }}>
                    <Autocomplete
                      multiple
                      options={machineList}
                      value={values.selectedMachine || []}
                      getOptionDisabled={() => {
                        const selectedMachinesCount =
                          values?.selectedMachine?.length || 0;
                        return selectedMachinesCount >= 3;
                      }}
                      getOptionLabel={(option) =>
                        option.dNSHostName ||
                        option.IPV4Address ||
                        "Unknown Machine"
                      }
                      onChange={(event, value) => {
                        const uniqueMachines = value.filter(
                          (machine, index, self) =>
                            index ===
                            self.findIndex(
                              (m) =>
                                m.dNSHostName?.toLowerCase() ===
                                  machine.dNSHostName?.toLowerCase() &&
                                m.id === machine.id,
                            ),
                        );
                        if (uniqueMachines.length <= 3) {
                          setFieldValue("selectedMachine", uniqueMachines);
                        } else {
                          alert("You can select a maximum of 3 machines.");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Machines "
                          variant="outlined"
                          error={
                            touched.selectedMachine && !!errors.selectedMachine
                          }
                          helperText={
                            touched.selectedMachine && errors.selectedMachine
                          }
                          fullWidth
                        />
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={
                              option.dNSHostName ||
                              option.IPV4Address ||
                              "Unknown Machine"
                            }
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                    />
                  </Box>
                )}
              <DialogActions>
                <Button onClick={closeModal} color="secondary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  form="invite-form"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Invite
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default InviteModal;
