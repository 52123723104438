import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Divider,
  Box,
  ListItemSecondaryAction,
  CircularProgress,
  Pagination,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "styled-components";
import InstallDesktopOutlinedIcon from "@mui/icons-material/InstallDesktopOutlined";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import DesktopAccessDisabledOutlinedIcon from "@mui/icons-material/DesktopAccessDisabledOutlined";
import Brightness7OutlinedIcon from "@mui/icons-material/Brightness7Outlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import RemoveFromQueueOutlinedIcon from "@mui/icons-material/RemoveFromQueueOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@mui/styles";
import { formatDateTime } from "../../../GenericMethods";
import { NGROK } from "../../../APIs";
import axios from "axios";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 1030px;
`;
const useStyles = makeStyles({
  root: {
    height: "950px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});
const RecentActivity = ({
  email,
  customMode,
  customStartDate,
  customEndDate,
  selectedTenantName,
  fromDetails,
  computerName,
  mode,
}) => {
  const [recentActivityData, setRecentActivityData] = useState(null);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [totalFetchedData, setTotalFetchedData] = useState([]);
  const [offsetPage, setOffsetPage] = useState(1);
  const [lastTime, setLastTime] = useState(null);
  const [reachedEnd, setReachedEnd] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const startIndex = (newPage - 1) * 10;
    const endIndex = newPage * 10;
    const newData = totalFetchedData.slice(startIndex, endIndex);

    setRecentActivityData(newData);
  };

  const handleRefresh = () => {
    setPage(1);
    getActivityData(true);
    setReachedEnd(false);
  };

  useEffect(() => {
    getActivityData(true);
  }, [
    mode,
    email,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
    fromDetails,
    computerName,
  ]);

  useEffect(() => {
    if (lastTime) {
      urlWithPage(1, cancelTokenSource, lastTime);
    }
  }, [lastTime]);

  const getIcons = (val) => {
    let str = val?.resourceType?.toLowerCase();
    switch (str) {
      case "audit":
        if (val?.message?.toLowerCase().includes("logged off")) {
          return (
            <LogoutOutlinedIcon
              style={{
                color: getIconsColor("logout"),
                fontSize: 30,
              }}
            />
          );
        } else if (
          val?.message?.toLowerCase().includes("reconnected") ||
          val?.message?.toLowerCase().includes("logged in")
        ) {
          return (
            <LoginOutlinedIcon
              style={{
                color: getIconsColor("login"),
                fontSize: 30,
              }}
            />
          );
        } else {
          return (
            <VerifiedUserOutlinedIcon
              style={{
                color: getIconsColor(str),
                fontSize: 30,
              }}
            />
          );
        }

      case "install":
        return (
          <InstallDesktopOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      case "enforce":
        return (
          <DesktopAccessDisabledOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      case "uninstall":
        return (
          <RemoveFromQueueOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      case "alert":
        return (
          <WarningAmberOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      case "appinstall blocked":
        return (
          <BlockOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      case "appinstall allowed":
        return (
          <TaskAltOutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
      default:
        return (
          <Brightness7OutlinedIcon
            style={{
              color: getIconsColor(str),
              fontSize: 30,
            }}
          />
        );
    }
  };

  const getIconsColor = (val) => {
    switch (val) {
      case "audit":
        return "blue";
      case "install":
        return "green";
      case "enforce":
        return "red";
      case "uninstall":
        return "red";
      case "alert":
        return "orange";
      case "appinstall blocked":
        return "red";
      case "appinstall allowed":
        return "green";
      case "logout":
        return "red";
      case "login":
        return "green";
      default:
        return "blue";
    }
  };

  const getActivityData = async (isFirstCall = false) => {
    try {
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Operation canceled due to new request.");
      }

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      if (!email || !selectedTenantName) return;

      setLoading(true);

      let bucket = "ondevice-auditlog";
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${mode}&email=${email}`;

      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }

      url += "&limitNeeded=false";

      if (selectedTenantName) {
        url += `&tenantName=${selectedTenantName}`;
      }

      if (fromDetails) {
        url += `&fromdetails=true&computerName=${computerName}`;
      }

      const firstPageResponse = await axios.get(url + "&pageNo=1&limit=500", {
        cancelToken: source.token,
      });

      let allData = [];

      if (
        firstPageResponse?.status === 200 &&
        firstPageResponse?.data.length > 0
      ) {
        allData = [...allData, ...firstPageResponse.data];
      } else {
        setReachedEnd(true);
      }
      let sortedData = allData.sort(
        (a, b) => new Date(b._time) - new Date(a._time),
      );

      setTotalFetchedData(sortedData);
      setRecentActivityData(sortedData.slice(0, 10));
      setLoading(false);
      setLastTime(sortedData[sortedData.length - 1]?._time);
    } catch (e) {
      console.log("Error fetching data:", e);
      if (axios.isCancel(e)) {
        console.log("Request canceled:", e.message);
      }
      setLoading(false);
    }
  };

  const urlWithPage = async (page, cancelTokenSource, lastTime) => {
    if (!email || !selectedTenantName) return;

    if (!lastTime) {
      console.log("No lastTime available");
      return;
    }

    if (cancelTokenSource) {
      cancelTokenSource.cancel("Operation canceled due to new request.");
    }

    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    let bucket = "ondevice-auditlog";
    let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${mode}&email=${email}`;

    if (customMode) {
      url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
    }

    url += "&limitNeeded=false";

    if (selectedTenantName) {
      url += `&tenantName=${selectedTenantName}`;
    }

    if (fromDetails) {
      url += `&fromdetails=true&computerName=${computerName}`;
    }

    const newUrl = `${url}&pageNo=${page}&limit=500&recentstartTime=${lastTime}`;
    try {
      const response = await axios.get(newUrl, {
        cancelToken: source.token,
      });

      if (response?.status === 200 && response?.data.length > 0) {
        setTotalFetchedData((prevData) => [...prevData, ...response.data]);

        const lastItem = response.data[response.data.length - 1];
      } else {
        setReachedEnd(true);
      }
      setLastTime(null);

      const pagesCount = Math.min(Math.ceil(totalFetchedData.length / 10), 100);
      if (pagesCount === 50) {
        setReachedEnd(true);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.log("Error fetching additional data:", error);
      }
      setReachedEnd(true);
    }
  };

  const maxPages = Math.min(Math.ceil(totalFetchedData.length / 10), 100);

  return (
    <>
      <Card>
        <CardHeader
          title="Recent Activity"
          action={
            <IconButton onClick={handleRefresh} aria-label="refresh">
              <RefreshIcon />
            </IconButton>
          }
        />
        <CardContent
          sx={{
            height: 1067,
          }}
        >
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "95%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <List
                  sx={{ bgcolor: "background.paper" }}
                  className={classes.root}
                >
                  {recentActivityData !== null && (
                    <>
                      {recentActivityData.map((dataVal, index) => (
                        <div key={index}>
                          <ListItem
                            alignItems="flex-start"
                            sx={{
                              py: 3,
                              px: 3,
                              maxWidth: 500,
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={dataVal?.resourceType}
                                style={{ backgroundColor: "transparent" }}
                              >
                                {getIcons(dataVal)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="body1" fontWeight="bold">
                                  {dataVal?.message}
                                </Typography>
                              }
                              secondary={
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-evenly",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ComputerOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.computerName?.length > 0
                                              ? dataVal?.computerName
                                              : "localhost"}
                                          </div>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <PersonOutlineOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.computerUserEmail
                                              ?.length > 0
                                              ? dataVal?.computerUserEmail
                                              : "localhost"}
                                          </div>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <FolderOutlinedIcon />
                                          <div
                                            style={{
                                              marginLeft: 5,
                                            }}
                                          >
                                            {dataVal?.resourceName?.length > 0
                                              ? dataVal?.resourceName
                                              : "localhost"}
                                          </div>
                                        </Box>
                                      </Box>
                                    </Typography>
                                  </Box>
                                </>
                              }
                            />
                            <ListItemSecondaryAction>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <AccessTimeOutlinedIcon />
                                  <div
                                    style={{
                                      marginLeft: 5,
                                    }}
                                  >
                                    {formatDateTime(dataVal?._time)}
                                  </div>
                                </Box>
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider variant="inset" component="li" />
                        </div>
                      ))}
                    </>
                  )}
                </List>
              </>
            )}
            <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
              {maxPages > 1 && (
                <Pagination
                  count={maxPages}
                  page={page}
                  onChange={handleChangePage}
                  variant="outlined"
                  shape="rounded"
                />
              )}
            </Box>
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default RecentActivity;
