import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import moment from "moment";
import { myLocalStorage } from "../../../../components/StorageHelper";

const GCPVMInstances = ({ tenantName }) => {
  const [loading, setLoading] = useState(false);
  const [vms, setVms] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedVmId, setSelectedVmId] = useState("");
  const [projectId, setProjectId] = useState(null);
  tenantName = myLocalStorage.getItem("latestTenant")?.tenantName;

  const selectedVm = (dbId) => {
    setSelectedVmId(dbId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchVmDatas = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/gcp/virtual-machines?tenantName=${tenantName}&projectId=${projectId}`,
      );
      if (response?.data) {
        setVms(response?.data);
      }
    } catch (e) {
      setVms([]);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (tenantName != null && projectId != null) fetchVmDatas(true, tenantName);
  }, [tenantName, projectId]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: tenantName,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
    } else {
      setProjectId(null);
    }
  };

  useEffect(() => {
    fetchGCPProjectId();
  }, [tenantName]);

  const filteredVmDatas = vms?.filter((vm) =>
    vm?.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = vms.filter((vm) => vm.id === selectedVmId);

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer component={Paper}>
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                },
                "& td, & th": {
                  border: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Virtual Machines
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : vms.length > 0 ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search VMs"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filteredVmDatas.map((vmsdata, index) => {
                      return (
                        <TableRow
                          key={index}
                          selected={vmsdata.id === selectedVmId}
                          onClick={() => {
                            selectedVm(vmsdata.id);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              border: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="flex-start"
                            >
                              <div></div>

                              <Box ml={2}>
                                <Typography
                                  variant="h6"
                                  sx={{ fontSize: "18px" }}
                                >
                                  {vmsdata.name}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No Vms Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedVmId && filteredData ? (
          <>
            <Box
              width={"50%"}
              sx={{
                border: "1px solid",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background: "#233044",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                  fontSize: "18px",
                  p: 4.8,
                }}
              >
                Details of {filteredData[0]?.name}
              </Typography>
              <List>
                {filteredData.length > 0 ? (
                  filteredData.map((vms, index) => {
                    return (
                      <div key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>VM Name</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {vms.name || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Zone</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {vms.zone || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Status</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {vms.status || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Machine Type</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{
                                  fontSize: 18,
                                  wordWrap: "break-word",
                                  whiteSpace: "normal",
                                }}
                              >
                                {vms.machineType || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Created At</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {moment(new Date()).format(
                                  "YYYY-MM-DD HH:mm",
                                ) || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </div>
                    );
                  })
                ) : (
                  <Typography variant="body1"> No details available</Typography>
                )}
              </List>
            </Box>
          </>
        ) : null}
      </Stack>
    </>
  );
};

export default GCPVMInstances;
