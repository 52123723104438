import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Typography,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Stack,
} from "@mui/material";
import { NGROK } from "../../../../APIs";
import AWSRequestAccessForm from "./AWSRequestAccessForm";
import useUserStore from "../../../../services/userStore";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { awsIcons } from "../AWSIcons";
import useNotificationsStore from "../../../../services/notificationStore";
import TimerForAWS from "../../../components/TimerForAWS";

const AccessAWSGroups = ({ user, groups }) => {
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onAWSRequestAccessForm, setOnAWSRequestAccessForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { role, userId } = useUserStore((state) => state.user);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const { userAWSNotifications } = useNotificationsStore();
  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClickRequestForm = (group) => {
    console.log(group);
    setSelectedGroup(group);
    setOnAWSRequestAccessForm(true);
  };
  const getSpecificUserGroup = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getSpecificUser?policyarn=${user.arn}&tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamgroups?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const fetchAllGroups = async (isFirst = false) => {
    if (isFirst) setLoading(true);
    if (role === "TENANT_USER") {
      try {
        const [specificUsersGroup, allGroups] = await Promise.all([
          getSpecificUserGroup(),
          getAllGroups(),
        ]);

        const updatedGroups = allGroups.map((group) => ({
          ...group,
          alreadyInGroup: specificUsersGroup?.groups?.some(
            (g) => g.arn === group.arn,
          ),
        }));

        setAllGroups(updatedGroups);
      } catch (error) {
        console.error("Error fetching groups:", error);
      } finally {
        setLoading(false);
      }
    } else {
      const [specificUsersGroup] = await Promise.all([getSpecificUserGroup()]);
      setAllGroups(
        specificUsersGroup?.groups !== null ? specificUsersGroup?.groups : [],
      );
      setLoading(false);
    }
  };

  const deleteTheGroupFromUser = async (group) => {
    const userNames = [];

    const { userName } = user;
    const { groupName } = group;
    userNames.push(userName);
    const response = await axios.post(
      `${NGROK}/api/aws/removeUserGroup?tenantName=${latestTenant}&groupName=${groupName}`,
      userNames,
    );
    fetchAllGroups();
    console.log(group, user, response);
  };

  const fetchResourceLevel = userAWSNotifications?.filter(
    (userAWSNotification) =>
      userAWSNotification.accessType === "groups" &&
      userAWSNotification.requestStatus === "APPROVED",
  );

  const checkAccessTiming = (arn) => {
    return fetchResourceLevel.find((data) => data.accessARN === arn);
  };

  useEffect(() => {
    fetchAllGroups(true);
    const interval = setInterval(fetchAllGroups, 5000);
    return () => clearInterval(interval);
  }, [user, groups, latestTenant]);
  const filteredGroups = allGroups?.filter((group) =>
    group.groupName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  if (loading) return <CircularProgress />;

  return (
    <>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
              },
              "& td, & th": {
                border: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  <Box display="flex" alignItems="center">
                    {awsIcons.groups}
                    <Box ml={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "18px",
                        }}
                      >
                        Groups
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups.length > 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Groups"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                    </TableCell>
                  </TableRow>
                  {filteredGroups.map((group, index) => {
                    const accessData = checkAccessTiming(group.arn);

                    return (
                      <TableRow key={index}>
                        <TableCell colSpan={role === "TENANT_USER" ? 1 : 2}>
                          <Stack
                            spacing={2}
                            display="flex"
                            alignItems="center"
                            direction={"row"}
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {awsIcons.group}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {group.groupName}
                            </Typography>
                          </Stack>
                        </TableCell>

                        {role === "TENANT_USER" ? (
                          group.alreadyInGroup ? (
                            <TableCell align="center">
                              <Button
                                color="primary"
                                variant="outlined"
                                disabled
                              >
                                Allowed
                              </Button>
                            </TableCell>
                          ) : accessData ? (
                            <TableCell>
                              <TimerForAWS
                                accessTimeOverAt={accessData.accessTimeOverAt}
                                resourceId={group.arn}
                                selectedTime={accessData.selectedTime}
                                setTimedResourcesWithoutAccess={
                                  setTimedResourcesWithoutAccess
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  handleClickRequestForm(group);
                                }}
                              >
                                Request Access
                              </Button>
                            </TableCell>
                          )
                        ) : (
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => {
                                deleteTheGroupFromUser(group);
                              }}
                            >
                              Remove membership
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      No Groups Available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedGroup && onAWSRequestAccessForm && (
        <AWSRequestAccessForm
          open={onAWSRequestAccessForm}
          onClose={() => {
            setOnAWSRequestAccessForm(false);
            setSelectedGroup(null);
          }}
          data={selectedGroup}
          title={selectedGroup?.groupName}
          user={user}
          from={"groups"}
        />
      )}
    </>
  );
};

export default AccessAWSGroups;
