import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import axios from "axios";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SecurityIcon from "@mui/icons-material/Security";
import InfoIcon from "@mui/icons-material/Info";

const GcpRoles = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState("all");
  const [projectId, setProjectId] = useState(null);
  const [selectedDetailsTab, setSelectedDetailsTab] = useState(0);

  const userData = useUserStore((state) => state.user);

  const handleDetailsTabChange = (event, newValue) => {
    setSelectedDetailsTab(newValue);
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/gcp-sync/manual`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/gcp/resource/get-roles?tenantName=${latestTenant}&projectId=${projectId}`,
    );
    if (response.status === 200) return response.data;
    else return [];
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [roles] = await Promise.all([getAllRoles()]);
      setRoles(roles);
      setLoading(false);
      if (initialLoad) {
        const filteredRoles = filterRoles(roles, filter);
        let selectRole = filteredRoles.length > 0 ? filteredRoles[0] : null;
        setSelectedRole(selectRole);
        // if (selectRole) getTheSelectedRoleDefinition(selectRole);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    const filteredRoles = filterRoles(roles, newFilter);
    setFilter(newFilter);
  };

  const handleRoleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    //getTheSelectedRoleDefinition(role);
  };

  const filterRoles = (roles, filter) => {
    if (filter === "default") {
      return roles?.filter((role) => !role.isCustom);
    } else if (filter === "custom") {
      return roles?.filter((role) => role.isCustom);
    }
    return roles;
  };

  const filteredRoles = filterRoles(
    roles?.filter(
      (role) =>
        role?.roleName &&
        role?.roleName.toLowerCase().includes(search.toLowerCase()),
    ),
    filter,
  );

  useEffect(() => {
    if (projectId != null) fetchData(true);
  }, [latestTenant, projectId]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const fetchGCPProjectId = async () => {
    const response = await axios.get(`${NGROK}/api/gcp/auth/configuration`, {
      params: {
        tenantName: latestTenant,
      },
    });
    if (response.data && response?.data?.length !== 0) {
      setProjectId(response.data[0].projectId);
    } else {
      setProjectId(null);
    }
  };

  useEffect(() => {
    fetchGCPProjectId();
  }, [latestTenant]);

  return (
    <>
      <Box>
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            fontSize: "22px",
          }}
        >
          GCP Roles
        </Typography>
        <Box
          display="flex"
          mt={5}
          mb={5}
          sx={{ width: "100%", justifyContent: "space-between" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {tenantList?.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              ) : null}
            </Box>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ ml: "auto" }}
              disabled={isSyncing}
              onClick={ondemandButton}
            >
              {isSyncing ? "Syncing" : "Sync"}
            </Button>
          </Box>
        </Box>
        {loading ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack spacing={5} mt={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ gap: 2 }}
              >
                <ToggleButtonGroup
                  value={filter}
                  exclusive
                  onChange={handleFilterChange}
                  aria-label="filter toggle buttons"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "auto",
                  }}
                >
                  <ToggleButton
                    value="all"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    All
                  </ToggleButton>
                  <ToggleButton
                    value="default"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Default
                  </ToggleButton>
                  <ToggleButton
                    value="custom"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    Custom
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Stack direction="row" spacing={5}>
                <Box
                  sx={{
                    width: "35%",
                    minWidth: "300px",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      maxHeight: "60vh",
                      overflow: "auto",
                      height: "100%",
                    }}
                  >
                    <Table
                      sx={{
                        width: "100%",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                      size="large"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Roles Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextField
                              label="Search Roles"
                              variant="outlined"
                              value={search}
                              onChange={handleRoleSearchChange}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        {filteredRoles.length > 0 ? (
                          filteredRoles.map((role) => (
                            <TableRow
                              key={role.id}
                              onClick={() => handleRoleClick(role)}
                              selected={role.id === selectedRole?.id}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "18px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Typography variant="h6">
                                  {role.roleName}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell align="center">
                              <Typography variant="h6">
                                No Roles Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box width="35%">
                  {selectedRole ? (
                    <>
                      <Box
                        sx={{
                          border: "1px solid",
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            background: "#233044",
                            color: "#fff",
                            padding: "10px",
                            borderRadius: "5px",
                            fontSize: "18px",
                            p: 4.8,
                          }}
                        >
                          Role Details
                        </Typography>
                        <>
                          <Tabs
                            value={selectedDetailsTab}
                            onChange={handleDetailsTabChange}
                          >
                            <Tab
                              icon={<InfoIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Info
                                </Typography>
                              }
                            />
                            <Tab
                              icon={<SecurityIcon />}
                              label={
                                <Typography sx={{ fontSize: 18 }}>
                                  Permissions
                                </Typography>
                              }
                            />
                          </Tabs>
                          {selectedDetailsTab === 0 && (
                            <>
                              <List>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Role Name:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.roleName}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Role Id:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.roleId}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                                <ListItem>
                                  <ListItemText
                                    primary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Description:</strong>
                                      </Typography>
                                    }
                                    secondary={
                                      <Typography sx={{ fontSize: 18 }}>
                                        {selectedRole?.description}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </>
                          )}
                          {selectedDetailsTab === 1 && (
                            <Box
                              mt={5}
                              sx={{
                                margin: "10px",
                                padding: "10px",
                              }}
                            >
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Attached Permissions:</strong>
                              </Typography>
                              <List>
                                {selectedRole?.permissions?.length > 0 ? (
                                  selectedRole?.permissions?.map(
                                    (permission, index) => (
                                      <ListItem key={index}>
                                        <ListItemText
                                          secondary={
                                            <Typography sx={{ fontSize: 18 }}>
                                              {permission}
                                            </Typography>
                                          }
                                        />
                                      </ListItem>
                                    ),
                                  )
                                ) : (
                                  <ListItem>
                                    <ListItemText
                                      primary={
                                        <Typography sx={{ fontSize: 18 }}>
                                          No Permission available
                                        </Typography>
                                      }
                                    />
                                  </ListItem>
                                )}
                              </List>
                            </Box>
                          )}
                        </>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Stack>
            </Stack>
          </>
        )}
      </Box>
    </>
  );
};

export default GcpRoles;
