import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Grid,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { makeStyles } from "@mui/styles";
import styled from "styled-components";
import { myLocalStorage } from "../../../components/StorageHelper";
import { awsIcons } from "./AWSIcons";
import useUserStore from "../../../services/userStore";
import moment from "moment/moment";

const Wrapper = styled.div`
  max-height: 500px;
`;

const useStyles = makeStyles({
  root: {
    maxHeight: "500px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
});

const AWSGroups = () => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rolesDetailsModalOpen, setRolesDetailsModalOpen] = useState(false);
  const [rolesPermissionJson, setRolesPermissionJson] = useState([]);
  const [policiesDetailsModalOpen, setPoliciesDetailsModalOpen] =
    useState(false);
  const [policiesDetails, setPoliciesDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const userData = useUserStore((state) => state.user);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loadingPolicies, setLoadingPolicies] = useState({});
  const [isSyncing, setIsSyncing] = useState(false);

  const handleSelectAllUsers = (event) => {
    if (event.target.checked) {
      const allUsernames = filteredUsers.map((user) => user.userName);
      setSelectedUsers(allUsernames);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleUserSelect = (userName) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userName)
        ? prevSelected.filter((name) => name !== userName)
        : [...prevSelected, userName],
    );
  };

  const handleDeleteMembership = async () => {
    const response = await axios.post(
      `${NGROK}/api/aws/removeUserGroup?tenantName=${latestTenant}&groupName=${selectedGroup.groupName}`,
      selectedUsers,
    );
    if (response) {
      ondemandButton();
    }
    console.log("Delete memberships for:", selectedUsers);
  };

  const handleUsersSearchChange = (event) => {
    const search = event.target.value;

    if (!search.trim()) {
      console.log(groupUsers);
      setFilteredUsers(groupUsers);
      return;
    }

    const filteredUsers = groupUsers.filter(
      (user) =>
        user.username &&
        user.username.toLowerCase().includes(search.toLowerCase()),
    );

    setFilteredUsers(filteredUsers);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setSelectedGroup(null);
    setSelectedUser(null);
    setSelectedUsers([]);
  };

  const handleGroupClick = (group) => {
    setSelectedGroup(group);
    setGroupUsers(group.users);
    setSelectedUser(null);
    setSelectedUsers([]);
  };

  const handleUserClick = (user) => {
    console.log(user);
    setSelectedUser(user);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const filteredGroups = groups.filter((group) =>
    group.groupName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamgroups?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamroles?tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.post(
      `${NGROK}/api/aws/ondemand?tenantName=${latestTenant}`,
    );
    setIsSyncing(false);
    fetchData(false, true);
  };

  const combinedPermissionandGetRoles = (groups, roles) => {
    return groups.map((group) => {
      const items = [...group.groupPolicies, ...(group.userPolicies || [])];
      const matchingRoles = [];

      roles.forEach((role) => {
        role?.rolePolicies?.forEach((policy) => {
          if (items.includes(policy.policyArn)) {
            matchingRoles.push({
              roleName: role.roleName,
              roleId: role.roleId,
              roleArn: role.roleArn,
            });
          }
        });
      });

      group.roles = matchingRoles;

      return group;
    });
  };

  const fetchData = async (
    initialLoad = false,
    isDeletedMemberShip = false,
  ) => {
    try {
      const [groupsData, rolesData] = await Promise.all([
        getAllGroups(),
        getAllRoles(),
      ]);

      let policiesConsolidation = combinedPermissionandGetRoles(
        groupsData,
        rolesData,
      );
      console.log(policiesConsolidation);
      setGroups(groupsData);

      setLoading(false);
      if (initialLoad && groupsData.length > 0) {
        const group = groupsData[0];
        setSelectedGroup(group);
        setGroupUsers(group.users);
      } else if (isDeletedMemberShip) {
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleViewButtonClick = async (policyARN) => {
    setLoadingPolicies((prev) => ({ ...prev, [policyARN]: true }));

    const policyDetails = await fetchPolicyDocDetails(policyARN);
    setLoadingPolicies((prev) => ({ ...prev, [policyARN]: false }));
    const policyDocumentString = policyDetails.policyDocument;
    const parsedPolicyDocument = JSON.parse(policyDocumentString);
    policyDetails.policyDocument = parsedPolicyDocument;
    console.log("Policy details:", policyDetails);
    setPoliciesDetails([policyDetails]);
    setPoliciesDetailsModalOpen(true);
  };

  const fetchPolicyDocDetails = async (policyArns) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/aws/getSpecificIAMPolicydetails?tenantName=${latestTenant}&policyarn=${encodeURIComponent(policyArns)}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching policy details:", error);
      return null;
    }
  };

  useEffect(() => {
    fetchData(true);
  }, [latestTenant]);
  useEffect(() => {
    if (selectedGroup) {
      setFilteredUsers(selectedGroup.users);
    }
  }, [selectedGroup]);

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "22px",
            }}
          >
            IAM Groups
          </Typography>
        </Box>
        <Box
          display="flex"
          mt={5}
          mb={5}
          sx={{
            display: "100%",
            justifyContent: "space-between",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
              }}
            >
              {tenantList?.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={selectedTenantName ? selectedTenantName : " "}
                  options={tenantList.map((tenant) => tenant.tenantName)}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Tenant List" />
                  )}
                  onChange={(e, value) => {
                    handleTenantChange(value);
                  }}
                />
              ) : null}
            </Box>
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={isSyncing}
              onClick={ondemandButton}
            >
              {isSyncing ? "Syncing" : "Sync"}
            </Button>
            <Button
              variant="outlined"
              color="error"
              disabled={selectedUsers.length === 0}
              onClick={handleDeleteMembership}
            >
              Delete Membership
            </Button>
          </Stack>
        </Box>
        {loading ? (
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Stack direction="row" spacing={4}>
              <Box width="30%">
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      height: "fit-content",
                      "& th": {
                        background: "#233044",
                        color: "#fff",
                      },
                      "& td, & th": {
                        border: "1px solid #233044",
                        fontSize: "18px",
                      },
                    }}
                    size="large"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Group Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredGroups.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={1} align="center">
                            No Groups Available
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell colSpan={1}>
                              <TextField
                                variant="outlined"
                                placeholder="Search groups"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                          {filteredGroups.map((group) => (
                            <TableRow
                              key={group.arn}
                              onClick={() => handleGroupClick(group)}
                              selected={group.arn === selectedGroup?.arn}
                              sx={{
                                "&:hover": {
                                  background: "#f5f5f5",
                                  cursor: "pointer",
                                },
                                "& td, & th": {
                                  border: "1px solid #233044",
                                  fontSize: "18px",
                                },
                                "&.Mui-selected": {
                                  background: "#233044 !important",
                                  "& td, & th": {
                                    color: "#fff",
                                  },
                                },
                              }}
                            >
                              <TableCell>
                                <Box display="flex" alignItems="center">
                                  {awsIcons.group}
                                  <Box ml={2}>
                                    <Typography variant="h6">
                                      {group.groupName}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box width="30%">
                {selectedGroup && (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{
                        height: "fit-content",
                        "& th": {
                          background: "#233044",
                          color: "#fff",
                        },
                        "& td, & th": {
                          border: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                      size="large"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Checkbox
                              indeterminate={
                                selectedUsers.length > 0 &&
                                selectedUsers.length < filteredUsers.length
                              }
                              checked={
                                selectedUsers.length === filteredUsers.length
                              }
                              onChange={handleSelectAllUsers}
                            />
                            User Name
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TextField
                              variant="outlined"
                              placeholder="Search users"
                              onChange={handleUsersSearchChange}
                              fullWidth
                            />
                          </TableCell>
                        </TableRow>
                        {filteredUsers.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={2} align="center">
                              No Users Available
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {filteredUsers.map((user) => (
                              <TableRow
                                key={user.userId}
                                selected={
                                  selectedUser?.username === user.username
                                }
                                onClick={() => handleUserClick(user)}
                                sx={{
                                  "&:hover": {
                                    background: "#f5f5f5",
                                    cursor: "pointer",
                                  },
                                  "& td, & th": {
                                    border: "1px solid #233044",
                                    fontSize: "18px",
                                  },
                                  "&.Mui-selected": {
                                    background: "#233044 !important",
                                    "& td, & th": {
                                      color: "#fff",
                                    },
                                  },
                                }}
                              >
                                <TableCell>
                                  <Box display="flex" alignItems="center">
                                    <Checkbox
                                      checked={selectedUsers.includes(
                                        user.username,
                                      )}
                                      onChange={() =>
                                        handleUserSelect(user.username)
                                      }
                                    />
                                    <Box display="flex" alignItems="center">
                                      {awsIcons.user}
                                      <Box ml={2}>
                                        <Typography variant="h6">
                                          {user.username}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Box>
              <Box width="30%">
                {selectedUser && (
                  <Box sx={{ border: "1px solid" }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        background: "#233044",
                        color: "#fff",
                        padding: "10px",
                        borderRadius: "5px",
                        fontSize: "18px",
                        p: 4.8,
                      }}
                    >
                      Details for User: {selectedUser.username}
                    </Typography>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="User Details Tabs"
                      sx={{ "& .MuiTab-root": { fontSize: 18 } }}
                    >
                      <Tab icon={awsIcons.user} label="User" />
                      <Tab icon={awsIcons.roles} label="Roles" />
                      <Tab icon={awsIcons.policies} label="Permissions" />
                    </Tabs>
                    <Box mt={2} sx={{ maxHeight: 300, overflowY: "auto" }}>
                      {tabValue === 0 && (
                        <List>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Name:</strong> {selectedUser.username}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>UserId:</strong> {selectedUser.userId}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>ARN:</strong> {selectedUser.arn}
                                </Typography>
                              }
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography sx={{ fontSize: 16 }}>
                                  <strong>Created At:</strong>{" "}
                                  {moment(selectedUser.createDate).format(
                                    "DD/MM/YYYY HH:mm:ss",
                                  )}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </List>
                      )}
                      {tabValue === 1 && (
                        <List>
                          {selectedGroup.roles.length > 0 ? (
                            selectedGroup.roles.map((role) => (
                              <ListItem
                                key={role.RoleId}
                                sx={{ cursor: "pointer" }}
                              >
                                <ListItemIcon>{awsIcons.roles}</ListItemIcon>
                                <ListItemText
                                  primary={role.roleName}
                                  primaryTypographyProps={{
                                    sx: { fontSize: 18 },
                                  }}
                                />
                              </ListItem>
                            ))
                          ) : (
                            <Typography align="center">
                              No roles assigned
                            </Typography>
                          )}
                        </List>
                      )}
                      {tabValue === 2 && (
                        <List>
                          {selectedGroup.groupPolicies.length > 0 ? (
                            selectedGroup.groupPolicies.map((permission) => (
                              <ListItem
                                key={permission.policyARN}
                                sx={{ cursor: "pointer" }}
                              >
                                <ListItemIcon>{awsIcons.policy}</ListItemIcon>
                                <ListItemText
                                  primary={
                                    permission.policyARN.split("/").length > 1
                                      ? permission.policyARN.split("/")[1]
                                      : permission.policyARN
                                  }
                                  primaryTypographyProps={{
                                    sx: { fontSize: 18 },
                                  }}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleViewButtonClick(permission.policyARN)
                                  }
                                  disabled={
                                    loadingPolicies[permission.policyARN] ||
                                    false
                                  }
                                  sx={{ marginLeft: 2 }}
                                >
                                  View
                                </Button>
                              </ListItem>
                            ))
                          ) : (
                            <Typography align="center">
                              No permissions assigned
                            </Typography>
                          )}
                        </List>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Stack>
          </>
        )}
      </Box>

      <Dialog
        open={rolesDetailsModalOpen}
        onClose={() => setRolesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Role Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              {rolesPermissionJson.map((role, index) => (
                <Grid item xs={12} key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "18px" }}
                  >
                    <strong>Role Name: </strong> {role.RoleName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "18px" }}
                  >
                    <strong>Role ID: </strong> {role.RoleId}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ fontSize: "18px" }}
                  >
                    <strong> Created Date:</strong> {role.CreatedDate}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "18px" }}>
                    <strong>Policies: </strong>
                  </Typography>
                  <List>
                    {role.policies.map((policy, index) => (
                      <React.Fragment key={index}>
                        <ListItem>
                          <ListItemText
                            primary={`Policy Name: ${policy.PolicyName}`}
                            secondary={`Policy ID: ${policy.PolicyId}`}
                            primaryTypographyProps={{ sx: { fontSize: 18 } }}
                          />
                        </ListItem>
                        <Typography sx={{ fontSize: 18, mt: 2 }}>
                          <strong>Policy Document:</strong>
                        </Typography>
                        {policy.PolicyDocument && (
                          <ListItem
                            primaryTypographyProps={{ sx: { fontSize: 18 } }}
                          >
                            <Paper
                              elevation={3}
                              style={{ padding: "5px", margin: "5px" }}
                            >
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Version:</strong>{" "}
                                {policy.PolicyDocument.Version}
                              </Typography>
                              {policy.PolicyDocument.Statement.map(
                                (statement, index) => (
                                  <Grid
                                    container
                                    key={index}
                                    spacing={2}
                                    style={{ marginTop: "10px" }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 18 }}
                                      >
                                        <strong>Statement {index + 1}:</strong>
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Effect:</strong>{" "}
                                        {statement.Effect}
                                      </Typography>
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Action:</strong>{" "}
                                        <List disablePadding>
                                          {statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 18 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      </Typography>
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Resource:</strong>{" "}
                                        {Array.isArray(statement.Resource) ? (
                                          <List>
                                            {statement.Resource.map(
                                              (resource, index) => (
                                                <ListItem
                                                  key={index}
                                                  disableGutters
                                                  sx={{ padding: 0 }}
                                                >
                                                  <ListItemText>
                                                    <Typography
                                                      sx={{ fontSize: 18 }}
                                                    >
                                                      {resource}
                                                    </Typography>
                                                  </ListItemText>
                                                </ListItem>
                                              ),
                                            )}
                                          </List>
                                        ) : (
                                          <Typography sx={{ fontSize: 18 }}>
                                            {statement.Resource}
                                          </Typography>
                                        )}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Paper>
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setRolesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={policiesDetailsModalOpen}
        onClose={() => setPoliciesDetailsModalOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Policy Details</DialogTitle>
        <DialogContent dividers>
          <Wrapper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ fontSize: "18px" }}>
                  <strong>Policies: </strong>
                </Typography>
                <List>
                  {policiesDetails?.map((policy, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={policy.policyName}
                          secondary={`Policy ID: ${policy.policyId}`}
                          primaryTypographyProps={{ sx: { fontSize: 18 } }}
                        />
                      </ListItem>
                      <Typography sx={{ fontSize: 18, mt: 2 }}>
                        <strong>Policy Document:</strong>
                      </Typography>
                      {policy.policyDocument && (
                        <ListItem
                          primaryTypographyProps={{ sx: { fontSize: 18 } }}
                        >
                          <Paper
                            elevation={3}
                            style={{ padding: "5px", margin: "5px" }}
                          >
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Version:</strong>{" "}
                              {policy.policyDocument.Version}
                            </Typography>
                            {policy.policyDocument.Statement.map(
                              (statement, index) => (
                                <Grid
                                  container
                                  key={index}
                                  spacing={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: 18 }}
                                    >
                                      <strong>Statement {index + 1}:</strong>
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    {statement.Sid && (
                                      <Typography sx={{ fontSize: 18 }}>
                                        <strong>Sid:</strong> {statement.Sid}
                                      </Typography>
                                    )}

                                    <Typography sx={{ fontSize: 18 }}>
                                      <strong>Effect:</strong>{" "}
                                      {statement.Effect}
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }}>
                                      <strong>Action:</strong>{" "}
                                      <List disablePadding>
                                        {Array.isArray(statement.Action) ? (
                                          statement.Action?.map(
                                            (action, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 18 }}
                                                  >
                                                    {action}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )
                                        ) : (
                                          <Typography sx={{ fontSize: 18 }}>
                                            {statement.Action}
                                          </Typography>
                                        )}
                                      </List>
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }}>
                                      <strong>Resource:</strong>{" "}
                                      {Array.isArray(statement.Resource) ? (
                                        <List>
                                          {statement.Resource.map(
                                            (resource, index) => (
                                              <ListItem
                                                key={index}
                                                disableGutters
                                                sx={{ padding: 0 }}
                                              >
                                                <ListItemText>
                                                  <Typography
                                                    sx={{ fontSize: 18 }}
                                                  >
                                                    {resource}
                                                  </Typography>
                                                </ListItemText>
                                              </ListItem>
                                            ),
                                          )}
                                        </List>
                                      ) : (
                                        <Typography sx={{ fontSize: 18 }}>
                                          {statement.Resource}
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          </Paper>
                        </ListItem>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Wrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPoliciesDetailsModalOpen(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AWSGroups;
