import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import "../pages.scss";
import { Box, Stack, TableContainer } from "@mui/material";
import { myLocalStorage } from "../../../components/StorageHelper";
import OperatingModeSelection from "./components/OperatingModeSelection";
import TenantSelection from "./components/TenantSelection";
import CreateProfileDialog from "./components/CreateProfileDialog";
import ProfilesTable from "./components/ProfilesTable";
import OperationSystemTab from "../EndpointsGroups/components/OperationSystemTab";

const Profiles = () => {
  const tenant = myLocalStorage.getItem("latestTenant");
  const storageOS = myLocalStorage.getItem("selectedOS") || "WINDOWS";

  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [osValue, setOsValue] = useState(storageOS);

  const [profilesList, setProfilesList] = useState([]);
  const [pendingProfiles, setPendingProfiles] = useState([]);

  const pendingProfilesFilteredByOs =
    pendingProfiles?.filter((pendingProfile) => {
      return (
        !profilesList.some(
          (profile) => profile.name === pendingProfile.profileName,
        ) && pendingProfile.operationSystem.toUpperCase() === osValue
      );
    }) || [];

  const fetchTenantProfiles = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/inherited?tenantName=${selectedTenant.tenantName}`,
      );
      setProfilesList(response.data);
    } catch (error) {
      setProfilesList([]);
      console.error(error, "error during fetch profiles");
    }
  };

  const fetchPendingProfiles = async () => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/get-pending?tenantName=${selectedTenant.tenantName}`,
      );

      setPendingProfiles(response.data);
    } catch (error) {
      setPendingProfiles([]);
      console.error(error, "error during fetch pending profiles");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const latestTenant = myLocalStorage.getItem("latestTenant");

      if (latestTenant && latestTenant !== selectedTenant) {
        setSelectedTenant(latestTenant);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedTenant]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedTenant) {
        fetchTenantProfiles();
        fetchPendingProfiles();
        myLocalStorage.setItem("latestTenant", selectedTenant);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, [selectedTenant]);

  return (
    <Stack direction={"column"} spacing={2} width={1200}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Stack direction={"row"} spacing={4}>
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
            setProfilesList={setProfilesList}
          />
          {selectedTenant ? (
            <OperatingModeSelection
              selectedTenant={selectedTenant}
              setSelectedTenant={setSelectedTenant}
            />
          ) : null}
        </Stack>
        <CreateProfileDialog
          fetchPendingProfiles={fetchPendingProfiles}
          profileList={profilesList}
          setPendingProfiles={setPendingProfiles}
          osValue={osValue}
        />
      </Box>

      <TableContainer sx={{ height: "fit-content" }}>
        <OperationSystemTab
          osValue={osValue}
          setOsValue={setOsValue}
          content={
            <ProfilesTable
              osValue={osValue}
              profilesList={profilesList}
              setProfilesList={setProfilesList}
              selectedTenant={selectedTenant}
              pendingProfiles={pendingProfilesFilteredByOs}
            />
          }
        />
      </TableContainer>
    </Stack>
  );
};

export default Profiles;
