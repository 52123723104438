import React, { useState } from "react";
import useUserStore from "../../../../services/userStore";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { awsIcons, azureIcons } from "../../AWS/AWSIcons";
import useNotificationsStore from "../../../../services/notificationStore";
import TimerForAWS from "../../../components/TimerForAWS";

import moment from "moment";
import AzureRequestResourceAccessForm from "./AzureRequestResourceAccessForm";

const AccessStorages = ({ publishedStorages, setPublishedStorages, user }) => {
  const { role, userId } = useUserStore((state) => state.user);
  const [selectedResource, setSelectedResource] = useState(null);
  const [
    onAzureRequestResourceAccessForm,
    setOnAzureRequestResourceAccessForm,
  ] = useState(false);
  const { userAzureNotifications } = useNotificationsStore();

  const [timedResourcesWithoutAccess, setTimedResourcesWithoutAccess] =
    useState([]);
  const handleClickRequestForm = (vm) => {
    setSelectedResource(vm);
    setOnAzureRequestResourceAccessForm(true);
  };

  const fetchResourceLevel = userAzureNotifications?.filter(
    (userAzureNotification) =>
      userAzureNotification.scopeType === "STORAGE_ACCOUNT" &&
      userAzureNotification.status === "APPROVED",
  );

  const checkAccessTiming = (scope) => {
    return fetchResourceLevel.find((data) => data.scope === scope);
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            height: "fit-content",
            "& th": {
              background: "#233044",
              color: "#fff",
            },
            "& td, & th": {
              border: "1px solid #233044",
              fontSize: "18px",
            },
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Created At</TableCell>
              {role === "TENANT_USER" ? <TableCell>Action</TableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {publishedStorages?.length > 0 ? (
              publishedStorages.map((storage, index) => {
                const accessData = checkAccessTiming(
                  storage.azureStorageAccountId,
                );

                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        {azureIcons.strgs}
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {storage.storageAccountName}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {storage.region}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box ml={2}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "18px",
                            }}
                          >
                            {moment(storage.syncedAt).format("DD/MM/YYYY")}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    {role === "TENANT_USER" ? (
                      accessData ? (
                        <>
                          <TableCell>
                            <TimerForAWS
                              accessTimeOverAt={accessData.validTo}
                              resourceId={storage.azureStorageAccountId}
                              selectedTime={accessData.expiryTimeAmount}
                              setTimedResourcesWithoutAccess={
                                setTimedResourcesWithoutAccess
                              }
                            />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleClickRequestForm(storage)}
                          >
                            Request Access
                          </Button>
                        </TableCell>
                      )
                    ) : null}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                    }}
                  >
                    No Storages available.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedResource && onAzureRequestResourceAccessForm && (
        <AzureRequestResourceAccessForm
          open={onAzureRequestResourceAccessForm}
          onClose={() => {
            setOnAzureRequestResourceAccessForm(false);
            setSelectedResource(null);
          }}
          data={selectedResource}
          title={selectedResource?.name}
          user={user}
          from={"STORAGE_ACCOUNT"}
        />
      )}
    </>
  );
};

export default AccessStorages;
