import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import { awsIcons } from "./AWSIcons";
import moment from "moment/moment";

const AWSUsers = () => {
  const [usersData, setUsersData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { role, userId, email } = useUserStore((state) => state.user);
  const [configureAccountModalOpen, setConfigureAccountModalOpen] =
    useState(false);
  const [awsUsername, setAwsUsername] = useState("");
  const [accountId, setAccountId] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isAWSUserConfigured, setIsAWSUserConfigured] = useState(false);
  const [isAWSConfigurationLoading, setIsAWSConfigurationLoading] =
    useState(false);
  const navigate = useNavigate();
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const userData = useUserStore((state) => state.user);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [isSyncing, setIsSyncing] = useState(false);

  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;

  const getSpecificUsers = async (name, arn) => {
    const response = await axios.get(
      `${NGROK}/api/aws/getSpecificUser?policyarn=${arn}&tenantName=${latestTenant}`,
    );
    return response.data;
  };

  const getAllUsers = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamusers?tenantName=${latestTenant}`,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };

  const getAllGroups = async () => {
    const response = await axios.get(
      `${NGROK}/api/aws/getIamgroups?tenantName=${latestTenant}`,
    );
    if (response?.status === 200) return response.data;
    else return [];
  };

  const fetchData = async () => {
    try {
      const [users, groups] = await Promise.all([
        getAllUsers(),
        getAllGroups(),
      ]);

      const combineData = {
        Users: users,
        Groups: groups,
      };
      console.log(combineData);
      setUsersData(combineData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const fetchAWSUserConfiguration = async (isInital = false) => {
    const response = await axios.get(
      `${NGROK}/api/aws/getAWSUserConfig?tenantName=${latestTenant}&tenantEmail=${email}`,
    );
    if (response?.data) {
      let { awsAccountARN, awsUserName } = response?.data;
      setIsAWSUserConfigured(awsAccountARN != null ? false : true);
      setConfigureAccountModalOpen(awsAccountARN != null ? false : true);
      if (awsAccountARN && awsUserName) {
        setIsAWSConfigurationLoading(true);
        myLocalStorage.setItem("userDetails", JSON.stringify(response?.data));
        fetchAWSConfigureDetails(awsUserName, awsAccountARN, isInital);
      }
    } else {
      setIsAWSUserConfigured(false);
      setConfigureAccountModalOpen(true);
    }
  };
  const fetchAWSConfigureDetails = async (
    userName,
    awsAccountARN,
    isInital,
  ) => {
    try {
      const [users] = await Promise.all([
        getSpecificUsers(userName, awsAccountARN),
      ]);

      const specificUserdata = users;
      setIsAWSConfigurationLoading(false);
      navigate("/aws/users/userDetails", {
        state: {
          user: specificUserdata,
          groups: specificUserdata?.groups,
          isconfigured: isInital,
        },
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const onDemand = async () => {
    const response = await axios.post(
      `${NGROK}/api/aws/ondemandSpecificUsers?tenantName=${latestTenant}`,
    );
  };

  const validateAndConfigureTheAwsUser = async () => {
    try {
      const response = await axios.post(
        `${NGROK}/api/aws/addAWSUserConfig?tenantName=${latestTenant}&tenantEmail=${email}&awsUserName=${awsUsername}&awsAccountARN=${accountId}`,
      );
      if (response?.data) {
        setAlertSeverity("success");
        setAlertMessage("AWS User successfully configured!");
        setOpenSnackbar(true);
        await onDemand();
        fetchAWSUserConfiguration(true);
      }
    } catch (ex) {
      setAlertSeverity("error");
      setAlertMessage("Failed to configure AWS user. Please try again.");
      setOpenSnackbar(true);
      console.error(ex);
    }
    setConfigureAccountModalOpen(false);
  };

  useEffect(() => {
    if (role !== "TENANT_USER") {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      fetchAWSUserConfiguration();
    }
  }, [latestTenant]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "awsUsername") {
      setAwsUsername(value);
    } else if (name === "accountId") {
      setAccountId(value);
    }

    if (awsUsername && accountId) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };

  const MAX_ITEMS_DISPLAYED = 2;

  const renderItemsWithMoreLink = (items, maxItems, user, groups) => {
    if (items.length > 0) {
      if (items.length <= maxItems) {
        return items.join(", ");
      } else {
        const displayedItems = items.slice(0, maxItems).join(", ");
        const remainingCount = items.length - maxItems;
        return (
          <>
            {displayedItems},{" "}
            <Link
              to={`/aws/users/userDetails`}
              state={{ user, groups, isconfigured: isAWSUserConfigured }}
            >
              {remainingCount} more
            </Link>
          </>
        );
      }
    } else {
      <Typography variant="body2">-</Typography>;
    }
  };

  const renderItemsWithMoreLinkAndConsolidating = (
    permissions,
    maxItems,
    user,
    groups,
  ) => {
    const items = consolidatepermissions(permissions);
    const policyNames = items.map((item) => item.policyName);

    if (policyNames.length <= maxItems) {
      return policyNames.join(", ");
    } else {
      const displayedItems = policyNames.slice(0, maxItems).join(", ");
      const remainingCount = policyNames.length - maxItems;
      return (
        <>
          {displayedItems},{" "}
          <Link
            to={`/aws/users/userDetails`}
            state={{ user, groups, isconfigured: isAWSUserConfigured }}
          >
            {remainingCount} more
          </Link>
        </>
      );
    }
  };

  const consolidatepermissions = (permissions) => {
    const userPolicies = permissions.userPolicies || [];
    const groupPolicies = permissions.groups
      ? permissions.groups.flatMap((group) => group.groupPolicies || [])
      : [];
    const consolidatedPermissions = [
      ...userPolicies.map((policy) => ({
        policyName: policy.policyName,
      })),
      ...groupPolicies.map((policy) => ({
        policyName: policy.policyName,
      })),
    ];
    return consolidatedPermissions;
  };

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.post(
      `${NGROK}/api/aws/ondemand?tenantName=${latestTenant}`,
    );
    setIsSyncing(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const filteredUsers = usersData
    ? usersData.Users.filter((user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : [];

  useEffect(() => {
    if (awsUsername.trim() && accountId.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [awsUsername, accountId]);

  return (
    <Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      {role !== "TENANT_USER" ? (
        <Stack spacing={5}>
          <Box display="flex" justifyContent="flex-start">
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  {tenantList?.length > 0 ? (
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={selectedTenantName ? selectedTenantName : " "}
                      options={tenantList.map((tenant) => tenant.tenantName)}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField {...params} label="Tenant List" />
                      )}
                      onChange={(e, value) => {
                        handleTenantChange(value);
                      }}
                    />
                  ) : null}
                </Box>
                <TextField
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ ml: "auto" }}
                  onClick={ondemandButton}
                  disabled={isSyncing}
                >
                  {isSyncing ? "Syncing" : "Sync"}
                </Button>
              </Box>
            </Stack>
          </Box>
          {loading && role !== "TENANT_USER" ? (
            <Box display={"flex"} p={5}>
              <CircularProgress />
            </Box>
          ) : !usersData ? (
            <Typography
              variant="body2"
              color="textSecondary"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              No datas available
            </Typography>
          ) : filteredUsers.length === 0 ? (
            <Typography
              variant="body2"
              color="textSecondary"
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              No Users available
            </Typography>
          ) : (
            <TableContainer style={{ height: "fit-content" }} component={Paper}>
              <Table
                sx={{
                  height: "fit-content",
                  maxWidth: "100%",
                  "& th": {
                    background: "#233044",
                    color: "#fff",
                  },
                  "& td, & th": {
                    border: "1px solid #233044",
                    fontSize: "16px",
                  },
                }}
                size="large"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        {awsIcons.user}
                        <Box ml={2}>
                          <Typography variant="h6">User Name</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        {awsIcons.groups}
                        <Box ml={2}>
                          <Typography variant="h6">Groups</Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        {awsIcons.policies}
                        <Box ml={2}>
                          <Typography variant="h6">Permissions</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={"center"}
                      >
                        <Box ml={2}>
                          <Typography variant="h6">Created At</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.map((user) => (
                    <TableRow key={user.username}>
                      <TableCell align="center">
                        <Link
                          to={`/aws/users/userDetails`}
                          state={{
                            user,
                            groups: usersData.Groups,
                            isconfigured: isAWSUserConfigured,
                          }}
                        >
                          {user.username}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {user?.groups?.length > 0
                          ? renderItemsWithMoreLink(
                              user?.groups?.map((group) => group.groupName) ||
                                [],
                              MAX_ITEMS_DISPLAYED,
                              user,
                              usersData.Groups,
                            )
                          : "-"}
                      </TableCell>

                      <TableCell align="center">
                        {renderItemsWithMoreLinkAndConsolidating(
                          user,
                          MAX_ITEMS_DISPLAYED,
                          user,
                          usersData.Groups,
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h6">
                          {moment(user.createDate).format(
                            "DD/MM/YYYY HH:mm:ss",
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      ) : (
        <>
          {isAWSUserConfigured ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setConfigureAccountModalOpen(true)}
              >
                Configure aws account
              </Button>
              <Dialog
                open={configureAccountModalOpen}
                onClose={() => {
                  setConfigureAccountModalOpen(false);
                }}
              >
                <DialogTitle>AWS User Configuration</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    label="AWS Username"
                    name="awsUsername"
                    fullWidth
                    variant="outlined"
                    value={awsUsername}
                    onChange={handleInputChange}
                  />
                  <TextField
                    margin="dense"
                    label="Account ARN"
                    name="accountId"
                    fullWidth
                    variant="outlined"
                    value={accountId}
                    onChange={handleInputChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setConfigureAccountModalOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!isButtonEnabled}
                    onClick={() => {
                      validateAndConfigureTheAwsUser();
                    }}
                  >
                    Validate
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : isAWSConfigurationLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "65vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default AWSUsers;
